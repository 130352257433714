import {
  useAddress,
  useContract,
  useClaimNFT,
  useNetworkMismatch,
  useNetwork,
  ChainId,
  useClaimedNFTSupply,
} from '@thirdweb-dev/react';
import ErrorMessage from 'components/ErrorMessage';

export default function Mint() {
  const address = useAddress();
  const { contract } = useContract('0x8F09005dca4e9141B6DD53ea5BCaBbeebFD608e4');
  const { mutate: claimNft, isLoading, error } = useClaimNFT(contract);
  const isMismatch = useNetworkMismatch();
  const [, switchNetwork] = useNetwork();
  const claimed = useClaimedNFTSupply(contract);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-xl font-medium leading-6 text-gray-900">
          Mint{' '}
          <button
            type="button"
            className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            onClick={() => {
              switchNetwork(ChainId.Polygon);
            }}
          >
            on Polygon
          </button>
        </h3>
        <p className="text-md font-medium text-purple-500">Free Mint!</p>

        <div className="mt-4 max-w-xl text-sm text-gray-500">
          <p>{`${claimed?.data || 0} / 2000 supply`}</p>
          <p>Unlimited allowed per wallet</p>
        </div>
        <form
          className="mt-5 sm:flex sm:items-center"
          onSubmit={e => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const quantity = parseInt(formData.get('amount'));
            if (isLoading || !isFinite(quantity)) return;
            claimNft({ to: address, quantity });
          }}
        >
          <div className="w-full sm:max-w-xs">
            <label htmlFor="amount" className="sr-only">
              Amount
            </label>
            <input
              type="number"
              name="amount"
              id="amount"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-600 focus:ring-purple-600 sm:text-sm"
              placeholder="1"
              min="1"
              defaultValue="1"
              disabled={isMismatch}
            />
          </div>
          <button
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-lilac-700 px-4 py-2 font-medium text-white shadow-sm hover:bg-lilac-600 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            disabled={isMismatch}
          >
            {isLoading && (
              <div
                className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-lilac-600 rounded-full mr-2"
                role="status"
                aria-label="loading"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Mint
          </button>
        </form>

        {error && isMismatch && (
          <ErrorMessage message="Please ensure you're on the Polygon Network" />
        )}
        {error && <ErrorMessage message="Unable to mint. Please try again" />}
      </div>
    </div>
  );
}
