import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from 'pages/Home';
import ThirdProvider from 'components/ThirdProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThirdProvider>
    <Home />
  </ThirdProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
