import Mint from 'components/Mint';
import { ConnectWallet } from '@thirdweb-dev/react';

export default function Home() {
  return (
    <div className="min-h-screen max-w-7xl pt-8 px-8 mx-auto">
      <div className="flex justify-end">
        <div className="w-46 md:w-72">
          <ConnectWallet />
        </div>
      </div>

      <div className="py-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
        <div className="max-w-lg lg:max-w-2xl lg:mx-0">
          <img className="h-20" src="/images/heart_lilac.png" alt="Sweet Talks" />
          <h1 className="mt-6 sm:mt-12 text-4xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-6xl">
            Sweet Talks
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Remember getting those boxes of little candy hearts when you were a kid? Now you can
            pick these special, limited-edition treats. Show them how sweet it is to be loved by
            you.
          </p>
        </div>

        <div className="max-w-lg lg:max-w-2xl lg:mx-0 mt-6">
          <Mint />
        </div>
      </div>
    </div>
  );
}
