export default function ErrorMessage(props) {
  const { message } = props;
  return (
    <div className="mt-2 text-sm text-purple-700">
      <p>
        <span className="font-bold">Error! </span>
        {message}
      </p>
    </div>
  );
}
